import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Environment } from 'src/environments/environment.interface';

/**
 * Injection token for global environment configuration.
 */
export const ENVIRONMENT_CONFIG = new InjectionToken<Environment>('environment-config', {
    providedIn: 'root',
    factory: () => environment
});
