
/**
 * Placeholder for the app version injection.
 */
export const AppVersionPlaceholder = '__app-version__';

/**
 * Placeholder for the app version commit sha injection.
 */
export const AppVersionShaPlaceholder = '__app-version-sha__';

export enum EnvironmentType {
    PROD = 'prod',
    STAGING = 'staging',
    DEV = 'dev',
    LOCAL = 'local'
}

export enum ApiConfigType {
    CUSTOMER_PORTAL = 'customer-portal'
}

/**
 * API configuration for external services.
 */
export interface ApiConfig {

    /**
     * The base URL of the endpoint.
     *
     * This assumes REST based endpoints.
     */
    baseURL: string;

}


/**
 * Environment based configuration for the overall application.
 */
export interface Environment {

    /**
     * The current Semver version of the application.
     *
     * This is injected into the application config at build time.
     */
    appVersion?: string;

    /**
     * The current version's short (7 character) commit hash.
     */
    appVersionSha?: string;

    /**
     * The Google Analytics tracking code.
     */
    gaTrackingCode?: string;

    /**
     * The hosted url of the application.
     */
    appBaseURL?: string;

    /**
     * Which environment the current build is for.
     */
    type: EnvironmentType;

    /**
     * The maximum time in seconds to wait for an
     * http call to complete.
     *
     * This is a global setting and can be used
     * as a baseline for each service.
     */
    http_request_timeout?: number;

    /**
     * All API configurations for external services.
     */
    apiConfigs?: {
        [key: string]: ApiConfig;
    }

}