import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from './tokens/environment.token';
import {
  AppVersionPlaceholder,
  AppVersionShaPlaceholder,
  Environment,
  EnvironmentType
} from 'src/environments/environment.interface';


@Injectable({
  providedIn: 'root'
})

/**
 * Environment service to get environment based configuration.
 */
export class EnvironmentService {

  public constructor(
    @Inject(ENVIRONMENT_CONFIG) private environmentConfig: Environment
  ) { }


  public isProduction(): boolean {
    return this.environmentConfig.type == EnvironmentType.PROD;
  }


  public isLocal(): boolean {
    return this.environmentConfig.type == EnvironmentType.LOCAL;
  }


  public isDevelopment(): boolean {
    return this.environmentConfig.type == EnvironmentType.DEV;
  }


  public isStaging(): boolean {
    return this.environmentConfig.type == EnvironmentType.STAGING;
  }


  /**
   * Reports the application's version, if available.
   */
  public getAppVersion(): string | undefined {

    if (this.environmentConfig.appVersion == AppVersionPlaceholder) {
      return undefined;
    }

    return this.environmentConfig.appVersion;

  }


  /**
   * Reports the application version's commit hash (sha), if available.
   */
  public getAppVersionSha(): string | undefined {

    if (this.environmentConfig.appVersionSha == AppVersionShaPlaceholder) {
      return undefined;
    }

    return this.environmentConfig.appVersionSha;

  }


  /**
   * Reports the base URL of the API configuration provided by the config key.
   *
   * @param configKey the API configuration key to lookup.
   * @returns The formatted base URL of the API configuration.
   */
  public getApiBaseURL(configKey: string): string | undefined {
    const config = this.environmentConfig.apiConfigs?.[configKey];
    return this.cleanUrl(config?.baseURL);
  }


  /**
   * Reports the intended base URL of the application.
   */
  public getAppBaseURL(): string | undefined {
    return this.cleanUrl(this.environmentConfig.appBaseURL);
  }


  /**
   * Removes the trailing forward slash of the url, if present.
   *
   * @param url the url to clean
   */
  private cleanUrl(url: string | undefined): string | undefined {
    if (!url) {
      return undefined;
    }

    if (url.trim().length == 0) {
      return undefined;
    }

    // Remove trailing slash, if any.
    if (url.endsWith('/')) {
      return url.substring(0, url.length - 1);
    }

    return url;
  }

}
