import { CommonEnvironment } from "./environment.common";
import { Environment, EnvironmentType, ApiConfigType } from "./environment.interface";


export const environment: Environment = {
    ...CommonEnvironment,
    type: EnvironmentType.DEV,
    appBaseURL: 'https://dev.blue-cardinal.io',
    apiConfigs: {
        [ApiConfigType.CUSTOMER_PORTAL]: {
            baseURL: 'https://app-dev.blue-cardinal.io'
        }
    }
};
