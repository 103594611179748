<mat-toolbar id="main-desktop-toolbar" class="primary-toolbar" *ngIf="!isHandheld">
    <div id="logo-link">
        <a mat-button href="/">Blue Cardinal</a>
    </div>
</mat-toolbar>
<mat-toolbar id="main-mobile-toolbar" *ngIf="isHandheld" class="primary-toolbar">
    <div class="logo-link">
        <a mat-button href="/">Blue Cardinal</a>
    </div>
</mat-toolbar>
