import { Environment, EnvironmentType } from "./environment.interface";


export const CommonEnvironment: Environment = {
    type: EnvironmentType.LOCAL,

    /**
     * This is just the placeholder for build time injection.
     *
     * This value is defined as a constant AppVersionPlaceholder
     * within the src/environments/environment.interface.ts.
     */
    appVersion: '3.0.0-dev.1',

    /**
     * This is just a placeholder for build time injection of
     * the version commit hash.
     *
     * This value is defined as a constant AppVersionShaPlaceholder
     * within the src/environments/environment.interface.ts.
     */
    appVersionSha: '1e6c314',

    http_request_timeout: 10000
};
